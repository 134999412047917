<template>
  <niceone-search :modelValue="initialValue" :placeholder="$t('search.search')" class="w-full" @click="onShow"/>
  <template v-if="show">
    <search-section v-model="show" :initial-value="initialValue"/>
  </template>
</template>

<script setup>
import SearchSection from "@/components/home/SearchSection.vue"
import {track} from "@/composables/useTrackEvent";
import {TRACKERS} from "@/constants/events";

const show = ref(false)

const props = defineProps({
  initialValue: {
    type: String,
    default: ""
  }
})
const onShow = () => {
  track(TRACKERS.SEARCH_INITATE)
  show.value = true
}
</script>