<template>
  <div v-if="recentSuggestion()" class="mb-2">
    <p class="flex justify-between items-center mb-2">
      <span class="font-niceone-bold">{{ $t('search.what_you_searched_for_recently') }}</span>
      <span
          class="text-grey-muted cursor-pointer"
          @click="$emit('clearRecentSearchHistory')"
      >{{ $t('search.clear_all') }}</span
      >
    </p>
    <div v-for="(recent, index) in recentSuggestion().slice(-5)" :key="`search-item-${index}`"
         class="flex justify-between items-center py-1 cursor-pointer" @click="$emit('getSeoUrl',recent)">
      <div class="flex items-center gap-2">
        <van-icon :name="$publicPath('/images/clock.svg')"></van-icon>
        <span v-html="recent.term??recent.title"/>
      </div>
      <van-icon :name="$publicPath('/images/search-arrow.svg')"></van-icon>
    </div>
  </div>
</template>

<script setup>
defineEmits(['clearRecentSearchHistory', 'getSeoUrl'])
const recentSuggestion = () => {
  if (JSON.parse(localStorage.getItem("allSuggestion")) !== null) {
    return JSON.parse(localStorage.getItem("allSuggestion")).sort((a, b) => {
      return a.search_query.localeCompare(b.search_query);
    });
  }
};
</script>

<style lang="scss" scoped>

</style>